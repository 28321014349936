<template>
  <l-map :zoom="zoom" :center="center" style="height:350px">
    <l-tile-layer :url="osmUrl"/>
    <l-routing-machine :waypoints="waypoints"/>
  </l-map>
</template>

<script>
import { LMap, LTileLayer } from 'vue2-leaflet'
import LRoutingMachine from '@/components/LRoutingMachine'
export default {
  name: "Map",
  model: {
    prop: 'obj',
  },
  props: {
    obj: Object,
    waypoints: Array,
  },
  components: { LMap, LTileLayer, LRoutingMachine },
  data(){
    return {
      zoom: 5,
      center: { lat: 46.878600, lng: 2.937520 },
      osmUrl: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png'
    }
  }  
};
</script>
